import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";
import { useState } from "react";

const FooterContainer = styled.footer`
    padding: 0.5rem 2rem 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    @media (max-width: 768px) {
        &.static {
            position: static;
        }
    }
`;

const Socials = styled.ul`
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 50%;
    justify-content: space-evenly;
`;

const FooterComp = ({ data, location }) => {
    const socials = data.allSanitySocials.nodes;

  return (  
  <FooterContainer className={location.pathname !== "/" ? "" : "static"}>
        <Socials> 
            {
                socials.map(({id, social, socialTitle, socialURL}) => {
                   return ( 
                       <li key={id}>
                            <a href={socialURL}>
                                <GatsbyImage image={getImage(social.asset)} alt={socialTitle} />
                            </a>
                        </li>)
                })
            }
        </Socials>
        <p>&copy; J. Marcelo Borromeo {new Date().getFullYear()}</p>
    </FooterContainer>
    )
}

export default function Footer(props) {
    return (
        <StaticQuery
            query={graphql`
                query Footer {
                    allSanitySocials {
                        nodes {
                        socialURL
                        socialTitle
                        social {
                            asset {
                            id
                            gatsbyImageData
                            }
                        }
                        id
                        }
                    }
                }
            `}

            render={
                data => <FooterComp data={data} {...props} />
            }
        />    
    )
}
