import React from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import "normalize.css";
import { GlobalStyles } from "../styles/GlobalStyles";


export default function Layout({ children, location }) {
    return (
        <div>
            <GlobalStyles />
            <Nav location={location}/>
            <div className="container">
                {children}
            </div>
            <Footer location={location} />
        </div>
    )
}