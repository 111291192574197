import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    
    html,
    body, 
    #___gatsby,
    #___gatsby > div,
    #___gatsby > div > div,
    .container {
        height: 100%;
    }


    body {
        color: #393939;
        font-family: "EB Garamond", serif;
        font-weight: 400;
        font-size: 16px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    font-family: "EB Garamond", serif;
    font-weight: 600;
    }

    h2 {
        font-size: 65px;
    }

    p {
    line-height: 21px;
    }

    a {
        text-decoration: none;
        color: #393939;
    }

    nav ul li a:hover, 
    nav ul li a.active {
        text-decoration: underline;
    }
`;