import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NavContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;

        li {
            margin-right: 40px;
            font-size: 22px;
        }
    }

    h1 {
        font-size: 30px;
    }

    @media (max-width: 768px) {
        padding-top: 2rem;
        ul {
            width: 100%;
            justify-content: space-evenly;
            li {
                margin-right: 0;
            }
        }
        h1 {
            display: none;
        }
    }

    @media (max-width: 480px) {
        ul {
            li { 
                font-size: 16px;
            }
        }
        
    }
`

export default function Nav({location}) {
    
    return (
        <NavContainer>
            <ul>
                <li>
                    <Link to="/about" activeClassName="active">About</Link>
                </li>
                <li>
                    <Link to="/writing" activeClassName="active">Writing</Link>
                </li>
                <li>
                    <Link to="/blog" activeClassName="active" partiallyActive={true}>Blog + News</Link>
                </li>
                <li>
                    <Link to="/contact" activeClassName="active">Contact</Link>
                </li>
                <li>
                    <a href="https://anchor.fm/the-req-room">Podcast</a>
                </li>
                <li>
                    <a href="https://miostark.substack.com/">Substack</a>
                </li>
            </ul>
            {
                location.pathname !== "/"  && <Link to="/"><h1>J. Marcelo Borromeo</h1></Link> 
            }
        </NavContainer>
    )
}